@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  .center {
    @apply flex items-center justify-center;
  }
}

@layer components {
  .content-container {
    @apply center flex-1;
  }

  .logo {
    @apply h-8;
  }

  .loader {
    @apply animate-spin text-gray-700;
  }

  .text-input {
    @apply h-10 w-full rounded border border-brand-grey-5 bg-brand-grey-5 px-2 text-sm text-white placeholder-transparent transition focus:border-brand-grey-4 focus:outline-none;
  }

  .text-input-label {
    @apply pointer-events-none absolute left-0 -top-3.5 h-10 font-sans text-xxs text-brand-grey-6 transition-all duration-200 peer-placeholder-shown:top-0 peer-placeholder-shown:left-2 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-10 peer-placeholder-shown:text-gray-400;
  }

  .material-icons-round {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";
  }

  .icon {
    @apply text-2xl;
  }

  .error-message {
    @apply font-sans text-xs text-red-600;
  }

  .description-404 a,
  .contact-page-description a {
    @apply text-brand-cyan-1 underline;
  }

  .tooltip-element {
    @apply relative;
  }

  .tooltip {
    @apply rounded bg-brand-grey-1 p-2 text-sm text-brand-grey-11;
  }

  .signup-button {
    @apply h-auto py-2;
  }

  .droppable-container {
    @apply space-y-2;
  }

  .droppable-container li {
    @apply list-none;
  }

  .font-landing,
  .font-landing *:not(.material-icons-round) {
    @apply font-heading;
  }

  .avatar {
    @apply border-4 border-brand-cyan-1 bg-brand-cyan-1 bg-cover bg-center bg-no-repeat;
  }
}
